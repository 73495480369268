$(document).ready(function () {
    document.querySelectorAll('.dropdown-menu').forEach(function (element) {
        element.addEventListener('click', function (e) {
            e.stopPropagation();
        });
    });
    if($('.footer-dropdown-menu .title').length>0){
        $('body').on('click', '.footer-dropdown-menu .title', function() {
            $(this).closest('.footer-dropdown-menu').toggleClass('active');
        });
    }
    if($('.btn-switch').length>0){
        $('body').on('click', '.btn-switch', function() {
            $(this).closest('.service-category').toggleClass('active');
        });
    }
    if($('.electricians-description .read-more').length>0){
        $('body').on('click', '.electricians-description .read-more', function() {
            $(this).closest('.desc').toggleClass('active');
        });
    }
    if($('.home-banner-slider').length>0){
        $('.home-banner-slider').slick({
            dots: true,
            infinite: true,
            adaptiveHeight: true,
            speed: 500,
            fade: true,
            prevArrow: $('.slick--prev'),
            nextArrow: $('.slick--next'),
            cssEase: 'linear'
        });
//        $('.home-banner-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
//            console.log(event);
//            console.log(nextSlide);
//            console.log(slick);
//            console.log(currentSlide);
//        });
    }
    if($('.google-review-box').length>0){
        $('.google-review-box').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: true,
            nextArrow: '<div class="slick-next"><svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.71929 5.29298L1.71929 0.29298C1.53069 0.110822 1.27808 0.0100274 1.01589 0.0123059C0.75369 0.0145843 0.502878 0.119753 0.31747 0.305161C0.132062 0.490569 0.0268926 0.741382 0.0246142 1.00358C0.0223358 1.26578 0.12313 1.51838 0.305288 1.70698L4.59829 5.99998L0.305288 10.293C0.209778 10.3852 0.133596 10.4956 0.0811869 10.6176C0.0287779 10.7396 0.00119157 10.8708 3.77567e-05 11.0036C-0.00111606 11.1364 0.0241857 11.268 0.0744666 11.3909C0.124747 11.5138 0.199001 11.6255 0.292893 11.7194C0.386786 11.8133 0.498438 11.8875 0.621334 11.9378C0.744231 11.9881 0.87591 12.0134 1.00869 12.0122C1.14147 12.0111 1.27269 11.9835 1.39469 11.9311C1.5167 11.8787 1.62704 11.8025 1.71929 11.707L6.71929 6.70698C6.90676 6.51945 7.01207 6.26514 7.01207 5.99998C7.01207 5.73482 6.90676 5.48051 6.71929 5.29298Z" fill="#1C1918"/></svg></div>',
            prevArrow: '<div class="slick-prev"><svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.292431 5.29298L5.29243 0.29298C5.48103 0.110822 5.73364 0.0100274 5.99583 0.0123059C6.25803 0.0145843 6.50884 0.119753 6.69425 0.305161C6.87966 0.490569 6.98483 0.741382 6.9871 1.00358C6.98938 1.26578 6.88859 1.51838 6.70643 1.70698L2.41343 5.99998L6.70643 10.293C6.80194 10.3852 6.87812 10.4956 6.93053 10.6176C6.98294 10.7396 7.01053 10.8708 7.01168 11.0036C7.01283 11.1364 6.98753 11.268 6.93725 11.3909C6.88697 11.5138 6.81272 11.6255 6.71883 11.7194C6.62493 11.8133 6.51328 11.8875 6.39038 11.9378C6.26749 11.9881 6.13581 12.0134 6.00303 12.0122C5.87025 12.0111 5.73903 11.9835 5.61703 11.9311C5.49502 11.8787 5.38468 11.8025 5.29243 11.707L0.292431 6.70698C0.10496 6.51945 -0.000355721 6.26514 -0.000355721 5.99998C-0.000355721 5.73482 0.10496 5.48051 0.292431 5.29298Z" fill="#1C1918"/></svg></div>',
            cssEase: 'linear',
            responsive: [
                {
                  breakpoint: 480,
                  settings: {
                    arrows: false,
                    dots: true,
                  }
                },
            ]
        });
        $('.google-review-box').slick('resize');
    }
    if ($('.real-clients').length) {
        $('.real-clients').slick({
            centerMode: true,
            centerPadding: '10px',
            dots: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 4,
            arrows: false,
            responsive: [
            {
              breakpoint: 5000,
              settings: {
                slidesToShow: 8,
                centerMode: true,
              }
            },
            {
              breakpoint: 3000,
              settings: {
                slidesToShow: 8,
                centerMode: true,
              }
            },
            {
              breakpoint: 2200,
              settings: {
                slidesToShow: 6,
                centerMode: true,
              }
            },
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                centerMode: true,
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
                centerMode: true,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                centerMode: true,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                centerMode: true,
              }
            },
        ]
        });
    }
    if ($('.logo-slider').length) {
        $('.logo-slider').slick({
//            centerMode: true,
//            centerPadding: '10px',
            dots: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 4,
            arrows: false,
            responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              }
            },
        ]
        });
    }
    if ($('.mind-slider').length) {
        $('.mind-slider').slick({
            dots: true,
            infinite: true,
            arrows: false,
            mobileFirst: true,
            responsive: [
            {
                breakpoint: 769,
                settings: "unslick"
            }
        ]
        });
    }
    if ($('.slider-init').length) {
        $('.slider-init').slick({
            dots: true,
            infinite: true,
            arrows: true,
            nextArrow: '<div class="common-slick next slick-next"><svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0402 10.9346L4.34435 1.21233C3.9409 0.858136 3.40054 0.662147 2.83966 0.666577C2.27877 0.671008 1.74224 0.875503 1.34562 1.23602C0.949006 1.59653 0.724032 2.08423 0.719158 2.59405C0.714284 3.10388 0.9299 3.59505 1.31957 3.96178L10.503 12.3093L1.31957 20.6568C1.11525 20.8361 0.952288 21.0507 0.840176 21.2879C0.728065 21.5252 0.669053 21.7803 0.666585 22.0385C0.664116 22.2967 0.718241 22.5527 0.8258 22.7917C0.93336 23.0307 1.0922 23.2478 1.29305 23.4303C1.4939 23.6129 1.73275 23.7573 1.99564 23.855C2.25854 23.9528 2.54022 24.002 2.82426 23.9998C3.1083 23.9975 3.389 23.9439 3.64998 23.842C3.91097 23.7401 4.14702 23.5919 4.34435 23.4062L15.0402 13.684C15.4412 13.3194 15.6665 12.8249 15.6665 12.3093C15.6665 11.7937 15.4412 11.2992 15.0402 10.9346Z" fill="white"/></svg></div>',
            prevArrow: '<div class="common-slick prev slick-prev"><svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.959327 10.9346L11.6552 1.21233C12.0586 0.858136 12.599 0.662147 13.1599 0.666577C13.7207 0.671008 14.2573 0.875503 14.6539 1.23602C15.0505 1.59653 15.2755 2.08423 15.2804 2.59405C15.2852 3.10388 15.0696 3.59505 14.6799 3.96178L5.4965 12.3093L14.6799 20.6568C14.8843 20.8361 15.0472 21.0507 15.1593 21.2879C15.2714 21.5252 15.3305 21.7803 15.3329 22.0385C15.3354 22.2967 15.2813 22.5527 15.1737 22.7917C15.0662 23.0307 14.9073 23.2478 14.7065 23.4303C14.5056 23.6129 14.2668 23.7573 14.0039 23.855C13.741 23.9528 13.4593 24.002 13.1753 23.9998C12.8912 23.9975 12.6105 23.9439 12.3495 23.842C12.0885 23.7401 11.8525 23.5919 11.6552 23.4062L0.959327 13.684C0.558295 13.3194 0.333008 12.8249 0.333008 12.3093C0.333008 11.7937 0.558295 11.2992 0.959327 10.9346Z" fill="white"/></svg></div>',
            responsive: [
            {
              breakpoint: 1200,
              settings: {
                arrows: false,
              }
            }]
        });
    }
    if ($('.switchboard-desktop-init').length) {
        $('.switchboard-desktop-init').slick({
            dots: true,
            infinite: true,
            arrows: true,
            prevArrow: '<div class="common-slick custom-slick-text slick-prev"><span class="svg-arrow"><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.375792 6.16083L6.79329 0.327497C7.03536 0.114979 7.35958 -0.00261405 7.69611 4.4103e-05C8.03264 0.00270226 8.35456 0.125399 8.59253 0.341709C8.8305 0.558018 8.96548 0.850633 8.96841 1.15653C8.97133 1.46243 8.84196 1.75713 8.60816 1.97716L3.0981 6.98566L8.60816 11.9942C8.73075 12.1018 8.82853 12.2305 8.8958 12.3729C8.96306 12.5152 8.99847 12.6683 8.99995 12.8232C9.00143 12.9781 8.96896 13.1317 8.90442 13.2751C8.83989 13.4185 8.74458 13.5487 8.62407 13.6583C8.50356 13.7678 8.36025 13.8545 8.20252 13.9131C8.04478 13.9718 7.87577 14.0013 7.70535 14C7.53492 13.9986 7.3665 13.9664 7.20991 13.9053C7.05332 13.8441 6.91169 13.7553 6.79329 13.6438L0.375792 7.8105C0.135173 7.59171 0 7.29502 0 6.98566C0 6.67631 0.135173 6.37961 0.375792 6.16083Z" fill="#F1592A"/></svg></span><div class="slick-text-cont"><span>POWERPOINT</span><span>INSTALLATION</span></div></div>',
            nextArrow: '<div class="common-slick custom-slick-text slick-next"><div class="slick-text-cont"><span>CAMERA</span><span>INSTALLATION</span></div><span class="svg-arrow"><svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.62421 6.16083L2.20671 0.327497C1.96464 0.114979 1.64042 -0.00261405 1.30389 4.4103e-05C0.967362 0.00270226 0.645444 0.125399 0.407472 0.341709C0.169501 0.558018 0.0345167 0.850633 0.0315923 1.15653C0.028668 1.46243 0.158038 1.75713 0.391837 1.97716L5.9019 6.98566L0.391837 11.9942C0.26925 12.1018 0.17147 12.2305 0.104203 12.3729C0.0369364 12.5152 0.00152938 12.6683 4.84608e-05 12.8232C-0.00143246 12.9781 0.0310423 13.1317 0.0955779 13.2751C0.160113 13.4185 0.255417 13.5487 0.375929 13.6583C0.49644 13.7678 0.639745 13.8545 0.797483 13.9131C0.95522 13.9718 1.12423 14.0013 1.29465 14C1.46508 13.9986 1.6335 13.9664 1.79009 13.9053C1.94668 13.8441 2.08831 13.7553 2.20671 13.6438L8.62421 7.8105C8.86483 7.59171 9 7.29502 9 6.98566C9 6.67631 8.86483 6.37961 8.62421 6.16083Z" fill="#F1592A"/></svg></span></div>',
            responsive: [
                {
                    breakpoint: 991,
                    settings: "unslick"
                }
            ]
        });
    }
    if ($('.switchboard-mobile-init').length) {
        $('.switchboard-mobile-init').slick({
            dots: true,
            infinite: true,
            arrows: false,
        });
    }
    
    if($('.switchboard-mobile-init').length>0 || $('.switchboard-desktop-init').length>0 || $('.mind-slider').length>0 || $('.google-review-box').length>0)
    $(window).on('resize', function() {
        $('.switchboard-mobile-init').slick('resize');
        $('.switchboard-desktop-init').slick('resize');
        $('.mind-slider').slick('resize');
        $('.google-review-box').slick('resize');
    });
    
    $("#iframeVideo").on('hidden.bs.modal', function (e) {
        $("#iframeVideo iframe").attr("src", $("#iframeVideo iframe").attr("src"));
    });
});